import $$ from 'jquery'
import exoEventBus from '@modules/exoEventBus'
import exoBridge from '@/common/exoBridge'
import exoApi from '@/common/exoApi'
import exoUtils from '@/common/exoUtils'
import {R_CHANGE_PASSWORD} from '@modules/routs'

let exologAdmin = {
    data: {
        user: {},
    },

    init() {
        this.addListeners()
        this.taskChangePassword();
        this.taskAdminLogin()
    },

    addListeners() {
        //hot-key for admin login Ctrl+Shift+X or Alt+X
        $$(document).on('keyup', e => {
            if (e.ctrlKey && e.shiftKey && e.which === 88) {
                this.showLoginPopup();
            }
        })

        $$(document).on('keydown', e => {
            if (e.altKey && e.which === 88) {
                this.showLoginPopup();
            }
        })

        //after login in popup
        exoEventBus.$on('exolog-confirm-login', () => {
            exoBridge.close();
            location.reload();
        })

        exoEventBus.$on('exolog-exo-modal-cancel', () => {
            //reload page to remove hash from URL
            if (data['exo-modal'] === 'change-password') {
                location.href = '/'
            }
        })
    },

    taskChangePassword() {
        //changePassword
        if (exoUtils.getUrlParams('changePassword')) {
            exoBridge.open({
                name: R_CHANGE_PASSWORD,
                query: {
                    changePasswordHash: exoUtils.getUrlParams('changePassword')
                }
            })
        }
    },

    taskAdminLogin() {
        if (exoUtils.getUrlParams('exologEditor')) {
            if (!$$('meta[name="exologEditor"]').length) {
                this.showLoginPopup();
            }
        }
    },


    showLoginPopup() {
        exoBridge.open({
            name: 'R_LOGIN',
            params: {}
        })
    },

    async userLogout() {
        if (window.ExologEditor) {
            await window.ExologEditor.checkUnsaved()
            await exoApi.post('auth/logout')
            let params = new URLSearchParams(location.search)
            params.delete('exologEditor')
            location.search = params.toString();
        }
    },

    loadCurrentUser() {
        return exoApi.post('/auth/getCurrentUser')
            .then(data => {
                    this.data.user = data;
                    exoEventBus.$emit('exolog-user-updated', data);
                    return data
                }
            )
    },

    exoBridge
};

window.exologAdmin = exologAdmin

export default exologAdmin
