import $$ from 'jquery'
import $exoRoot from '@/common/exoRoot'

import {
    template,
    templateBody,
    templateBodyOuter,
    templateFooter,
    templateFooterOuter,
    templateHeader,
    templateHeaderOuter
} from './templates'

class Popup {
    constructor(options) {


        let opts = {
            title: 'Exolog',
            message: '',
            template,
            templateBody,
            templateBodyOuter,
            templateFooter,
            templateFooterOuter,
            templateHeader,
            templateHeaderOuter,
            labels: {
                OK: 'OK',
                CANCEL: 'CANCEL'
            },
            parent: $exoRoot,
            variant: 'primary'
        }
        opts = $$.extend(true, opts, options)

        this.$win = $$('<div class="exo-popup-wrapper"></div>')
        this.options = opts
        this.$parent = $$(opts.parent)
        this.defaultPrevented = false
        this.setInnerHtml()
        this.promise = this.show()
    }

    setInnerHtml() {
        let html = this.options.template(this.options)
        this.$win.append(`<div class="exo-popup exo-popup--${this.options.variant} ${this.options.className}">${html}</div>`)
        this.addActionHandler()
    }

    keyupListener(e) {
        switch (e.code) {
            case 'Escape':
                this.onAction(e)
        }
    }

    show() {
        this.defaultPrevented = false
        this.$parent.append(this.$win)
        this.$win.find('.exo-popup__ok').focus()
        this.listener = e => this.keyupListener(e)
        window.addEventListener("keydown", this.listener)
        return new Promise((resolve) => {
            this.resolve = resolve
        })
    }

    hide() {
        window.removeEventListener("keydown", this.listener)
        this.$win.remove()
    }

    addActionHandler() {
        this.$win.find('.exo-popup__footer button').on('click', this.onAction.bind(this))
    }

    onAction(e) {
        e.preventDefault()

        if (e.code === "Escape") {
            this.trigger = "Escape"
        } else {
            this.trigger = $$(e.target).attr('data-trigger')
        }

        this.value = this.$win.find('[data-value]').val()
        if (this.options.onAction) this.options.onAction(this)
        this.resolve(this)
        if (!this.defaultPrevented) this.hide(); else this.defaultPrevented = false;
    }

    preventDefault() {
        this.defaultPrevented = true
    }
}

export default Popup

