import axios from 'axios'
import exoDialogs from '@/common/exoDialogs'

function Api() {
    let service = axios.create({
        baseURL: '/api/',
        withCredentials: false,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    service.interceptors.request.use(this.handleRequest.bind(this));
    service.interceptors.response.use(this.handleSuccess.bind(this), this.handleError.bind(this));
    this.service = service;
}

Api.prototype.handleRequest = function (config) {
    this.preventShowError = config.preventShowError || false

    if (!config.data) {
        config.data = {}
    }
    config.data.fullapi = true
    return config;
}

Api.prototype.handleSuccess = function (response) {
    if (!response.data.success) {

        //normalize
        response.message = response.data.error

        //Support custom 401 for old API
        if (response.data.code === 401) {
            ///store.dispatch(AUTH_LOGOUT)
            catchApiError(response);
        } else {
            if (response.message === undefined) response.message = 'Unknown error or no response from the server. Please contact the administrator.';
            if (!this.preventShowError) catchApiError(response);
        }
        return Promise.reject(response)
    }

    if (response.data && response.data.info) {
        if (Array.isArray(response.data.info)) {
            response.data.info.forEach((info) => {
                exoDialogs.info(info);
            })
        } else {
            exoDialogs.info(response.data.info)
        }
    }
    /*if (!data) {
        exoDialogs.warning(response.data.info);
    }*/
    //exoEventBus.$emit('exo-api-call:'+response.config.url.replace('/api/',''), data)
    return response.data.data;
}

Api.prototype.handleError = function (error) {
    return new Promise(function (resolve, reject) {
        throw error;
    });
}

export default new Api().service;

function catchApiError(err) {
    if (err && err['message']) err = err['message']
    exoDialogs.alert(err);
}
